<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
  >
  </v-select>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const badgesRepository = RepositoryFactory.get("badge");

export default {
  data: () => ({
    items: []
  }),
  methods: {
    getCategories() {
      this.items = badgesRepository.getCategories();
    }
  },
  mounted() {
    this.getCategories();
  }
};
</script>
